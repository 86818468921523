<template>
    <div class="page pade--about" >
        <div class="container">
            <div class="page-container">
                <div class="page-container__header">
                    <a href="/" class="logo"><img src="/img/logo.svg"></a>
                </div>
                <div class="page-container__section">
                    <RouterView></RouterView>
                </div>
            </div>
        </div>
    </div>
</template>
